import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Posts from '~/components/Posts/Posts';

const BlogIndex = ({ data }) => {
  const { pages, social, title, calendly } = data.site.siteMetadata;
  const meta = pages.blog;

  const posts = data.allMarkdownRemark.edges.map((edge) => ({
    id: edge.node.id,
    ...edge.node.frontmatter,
    excerpt: edge.node.excerpt
  }));

  return (
    <Layout meta={meta} socials={social} title={title} calendly={calendly}>
      <div className="page">
        <h1 className="page-title">Blog</h1>
        <Posts posts={posts} />
      </div>
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndex {
    allMarkdownRemark(
      limit: 100
      filter: { frontmatter: { path: { regex: "/.*blog.*$/" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 380)
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            path
            cover {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        calendly {
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        pages {
          blog {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
