import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import './Posts.css';

const Post = ({ post }) => (
  <div className="post">
    <h2 className="post-title">
      <Link to={post.path}>{post.title}</Link>
    </h2>

    <p className="post-date">{post.date}</p>

    <p className="post-intro">{post.excerpt}</p>

    {post.tags && (
      <ul className="post-tags">
        {post.tags.map((tag) => (
          <li key={tag} className="post-tag">
            <Link to={`/tags/${tag}`}>{tag}</Link>
          </li>
        ))}
      </ul>
    )}
  </div>
);

Post.propTypes = {
  post: PropTypes.object
};

const Posts = ({ posts }) => (
  <div className="posts">
    {posts.map((post) => (
      <Post post={post} key={post.id} />
    ))}
  </div>
);

Posts.propTypes = {
  posts: PropTypes.array
};

export default Posts;
